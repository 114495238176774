import Vue from "vue";
import App from "./App.vue";
import router from "./routes";
import store from "./store";
import VueRouterBackButton from "vue-router-back-button";
import vuetify from "../plugins/vuetify";
import "../prototypes";

import(/* webpackChunkName: "qrcode" */ "vue-qrcode-reader").then(
  ({ default: VueQrcodeReader }) => {
    Vue.use(VueQrcodeReader);
  }
);

Vue.config.productionTip = true;

Vue.use(VueRouterBackButton, {
  router,
  ignoreRoutesWithSameName: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
