import { AdminRole, ClientRole } from "../carer/roles";

export const BOOKING_STATUSES = {
  requestOnProgress: "requestOnProgress",
  shiftWaiting: "shiftWaiting",
  rejected: "rejected",
  cancelled: "cancelled",
  allShiftCancelled: "allShiftCancelled",
  paymentPending: "paymentPending",
  paymentCompleted: "paymentCompleted",
  clockInPending: "clockInPending",
  clockOutPending: "clockOutPending",
  waitingForReview: "waitingForReview",
  completed: "completed",
  expired: "expired",
};

export const JOB_POST_ENUM = {
  APPLIED: "applied",
  ACCEPTED: "accepted",
  CANCELED: "cancelled",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  // Next Action Status
  WAITING_FOR_RESPONSE: "waitingForResponse",
  NOT_FILLED: "notFilled",
  FILLED: "filled",
  WAITING_FOR_ACCEPT: "waitingForAccept",
  READY_FOR_BOOKING: "readyForBooking",
};

export const JOB_POST_STATUS = {
  [JOB_POST_ENUM.APPLIED]: "Applied",
  [JOB_POST_ENUM.CANCELLED]: "Job Cancelled",
  [JOB_POST_ENUM.ACCEPTED]: "accept",
  [JOB_POST_ENUM.CANCELED]: "Cancel",
  [JOB_POST_ENUM.REJECTED]: "Rejected",
};

export const BOOKING_EDIT_ALLOWED_ROLES = [
  AdminRole.SuperAdmin,
  AdminRole.Maintainer,
  ClientRole.ApprovedOrganisation,
];
