import getState from "./state";

/* eslint-disable no-param-reassign,no-unused-vars */

export const clearData = (state) => {
  localStorage.removeItem("admin-access-token");

  const rootState = getState();

  Object.keys(rootState).forEach((key) => {
    state[key] = rootState[key];
  });
};

export const setSignUpEmail = (state, nextState) => {
  state.signUpEmail = nextState;
};

export const setTwoFactor = (state, nextState) => {
  state.signUpEmail = nextState;
};

export const setUser = (state, nextState) => {
  state.user = nextState;
};

export const setUsersFilters = (state, nextState) => {
  state.filters.users = nextState;
};

export const lock = (state, nextState) => {
  state.locked = true;
};

export const unlock = (state, nextState) => {
  state.locked = false;
};

export const setAppCount = (state, nextState) => {
  state.appCount = nextState;
};

export const setLastKnownRoute = (state, nextState) => {
  state.lastKnownRoute = nextState;
};

// Care workers
export const setCarerSearchText = (state, nextState) => {
  state.carerSearchText = nextState;
};

// Care workers
export const setClientSearchText = (state, nextState) => {
  state.clientSearchText = nextState;
};

//Booking
export const updateBookingFilters = (state, nextState) => {
  state.bookingFilters = { ...state.bookingFilters, ...nextState };
};

export const setSelectedCarer = (state, nextState) => {
  state.selectedCarer = nextState;
};
export const setSelectedClient = (state, nextState) => {
  state.selectedClient = nextState;
};
