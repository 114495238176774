export const IdentityEndpoints = {
  login: "/auth/login",
  logout: "/auth/logout",
  resetReq: "/auth/reset/request",
  reset: "/auth/reset",
  token: "/auth/token",
  loginWithToken: "/auth/login-with-token",
  loginToken: "/auth/:userId/login-token",
  serviceUserLoginToken: "/auth/:userId/login-token/:serviceUserId",
  enabledMFAs: "/auth/enabled-mfa",
  otpSetup: "/auth/otp/setup",
  otpVerify: "/auth/otp/verify",
  fidoCredentialCreationOptions: "/auth/fido/credential-options",
  fidoAuthOptions: "/auth/fido/auth-options",
  fidoRegisterCredential: "/auth/fido/register",
  fidoverifyCredential: "/auth/fido/verify",
  fidoGetRichAuthToken: "/auth/fido/rich-auth",
  userInfo: "/users/info",
  users: "/users",
  updateUser: "/users/:id",
  registerCarer: "/users/carer",
  registerClient: "/users/client",
  authPin: "/authPin/:id",
  versionControl: "/version-control",
  versionControlById: "/:id/version-control/",
};
