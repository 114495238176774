<template>
  <v-app :style="`background: ${theme.background}`">
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import IdleTracker from "idle-tracker";
import ms from "ms";
import { authServiceInstance } from "@auth/";
import useRegisterSw from "../mixins/useRegisterSw";

export default {
  name: "UCC",
  data() {
    return {
      mDrawer: null,
      idleTracker: null,
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.currentTheme;
    },
    ...mapState(["user"]),
  },
  mixins: [useRegisterSw],
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-rigt" : "slide-left";
    },
  },
  async beforeCreate() {
    this.idleTracker = new IdleTracker({
      timeout: ms("10m"),
      onIdleCallback: () => {
        this.lockAdminPanel();
      },
    });
    this.idleTracker.start();

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        window.location.reload();
        this.refreshing = true;
      });

      navigator.serviceWorker.addEventListener("message", (msg) => {
        this.$router.push(msg.data);
      });
    } else {
      console.error("Could not register service worker");
    }
  },
  beforeDestroy() {
    this.idleTracker?.end();
  },
  mounted() {},
  methods: {
    lockAdminPanel() {
      if (!this.$route.path.includes("/auth")) {
        authServiceInstance.lockAdminPanel();
        this.$router.push("/auth/lock");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../scss/index.scss";
</style>
