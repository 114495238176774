import { NavigationGuard } from "vue-router";
import { authServiceInstance } from ".";

export const AdminDashboardGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsAdmin()) {
    next("/auth/login");
  } else if (authServiceInstance.isLocked()) {
    next("/auth/lock");
  } else {
    next();
  }
};

export const AdminLockGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isLocked()) {
    next("/dashboard");
  } else {
    next();
  }
};

export const AdminAuthGuard: NavigationGuard = (to, from, next) => {
  if (
    authServiceInstance.isAuthenticatedAsAdmin() &&
    !authServiceInstance.isLocked()
  ) {
    next("/dashboard");
  } else {
    next();
  }
};

export const AdminLoginGuard: NavigationGuard = (to, from, next) => {
  if (
    authServiceInstance.isAuthenticatedAsAdmin() &&
    !authServiceInstance.isLocked()
  ) {
    next("/dashboard");
  } else if (
    authServiceInstance.isAuthenticatedAsAdmin() &&
    authServiceInstance.isLocked()
  ) {
    next("/auth/lock");
  } else {
    next();
  }
};

export const MFASetupGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isMfaSetupRequired()) {
    next("/auth/login");
  } else {
    next();
  }
};

export const MFAGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isMfaCodeRequired()) {
    next("/auth/login");
  } else {
    next();
  }
};
