import { RootState } from "app/src/index/store/root/state";

export const getBookingFilters = (state: RootState) => {
  const bookingFilters = structuredClone(state.bookingFilters || {});
  (
    Object.keys(bookingFilters) as Array<keyof RootState["bookingFilters"]>
  ).forEach(
    (key) => bookingFilters[key] === undefined && delete bookingFilters[key]
  );

  return bookingFilters;
};
