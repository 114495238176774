import { IdentityAPI } from "@apiV2";

export const getUserInfo = async (context: any) => {
  const { data, error } = await IdentityAPI.getUserInfo();
  if (!error) {
    context.commit("setUser", data);
  }
};

export const lockAdminPanel = async (context: any) => {
  context.commit("lock", true);
};
