import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import ROOT from "./root";
// import { isSomething } from "@helpers";

/**
 * The version number for the app. Should be updated
 * every time the app is updated for the client
 */
// const version = "1.0.0";

const { state, actions, mutations, getters } = ROOT;

Vue.use(Vuex);

export const INITIAL_STATE = {
  applicationStatus: null,
  user: null,
  token: null,
};

// const prevState = localStorage.getItem('vuex');

// const getCrucialState = (prevState) => {
//   if (!isSomething(prevState)) {
//     return {};
//   }

//   const prevStateJ = JSON.parse(prevState);

//   const { user } = prevStateJ;

//   return {
//     user,
//   };
// };

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  // plugins: [
  //   createPersistedState({
  //     key: "adminVuex",
  //     paths: ["notifications", "user", "locked"],
  //     getState: () => {
  //       try {
  //         // Get the state from localstorage
  //         const prevState = localStorage.getItem("adminVuex");

  //         const storedVersion = localStorage.getItem("adminVersion");

  //         console.info(
  //           `%cAdmin Application version: ${storedVersion}`,
  //           "background: #222; color: #bada55"
  //         );

  //         if (storedVersion !== version) {
  //           console.warn(
  //             `%cAdmin Application version mismatach:
  //           stored is: ${storedVersion} and current is: ${version}`,
  //             "color: #bada55"
  //           );
  //           const newState = getCrucialState(prevState);

  //           localStorage.setItem("adminVersion", version);

  //           return newState;
  //         }

  //         return JSON.parse(prevState);
  //       } catch (err) {
  //         console.error(err);

  //         localStorage.setItem("adminVersion", version);
  //         return {};
  //       }
  //     },
  //   }),
  // ],
});

export default store;
