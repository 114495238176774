import { OngoingBookingStatuses } from "app/src/index/store/root/state";

export default () => ({
  user: null,
  locked: false,
  filters: {
    users: null,
  },
  appCount: 0,
  notifications: {
    dialog: false,
    items: [],
  },
  routeItem: null,
  drawer: null,

  // Care workers
  carerSearchText: "",

  // Clients
  clientSearchText: "",

  lastKnownRoute: "/panel",
  bookingFilters: {
    status: OngoingBookingStatuses,
  },
  selectedCarer: null,
  selectedClient: null,
});
